import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="termdiv">
      <h1 className="main_title">Privacy Policy</h1>
      <p className=""> This Privacy Policy describes the policies of Auxo Innovations, 169 Madison Ave, Suite 2501, New York 10016, United States of America (the), email:{" "} <a href="mailto:info@auxoinnovations.com" className="text-[#15c] underline"> info@auxoinnovations.com </a> , phone: +1(917) 423-7218 on the collection, use and disclosure of your information that we collect when you use the website of WellGenPro ( <a href="https://www.wellgenpro.com/" target="_blank" className="text-[#15c] underline"> www.wellgenpro.com </a> ) or any of the services we provide (the “Service”). By accessing or using the Service, you are consenting to the collection, use and disclosure of your information in accordance with this Privacy Policy. If you do not consent to the same, please do not access or use the Service </p>
      <p className=""> We may modify this Privacy Policy at any time without any prior notice to you and will post the revised Privacy Policy on the Service. The revised Policy will be effective 180 days from when the revised Policy is posted in the Service and your continued access or use of the Service after such time will constitute your acceptance of the revised Privacy Policy. We therefore recommend that you periodically review this page </p>
      <h3>Information We Collect</h3>
      <p className=""> We will collect and process any or all of the following personal information about you: Name, Email, Mobile Phone Number, Social Media Profile(s), Date of Birth, Address(es), Payment Information.{" "} </p>
      <h3>How We Use Your Information</h3>
      <p className=""> We will use the information that we collect about you for any of all of the following purposes: Marketing/ Promotional, Creating user account, Testimonials, Customer feedback collection, Enforce T&C, Processing payment, Support, General Administration, Targeted advertising, Site protection, Dispute resolution, Manage user account(s).{" "} </p>
      <p className=""> If we want to use your information for any other purpose(s), we will ask you for consent and will use your information only on receiving your consent and then, only for the purpose(s) for which grant consent unless we are required to do otherwise by law. </p>
      <h3>How We Share Your Information</h3>
      <p className=""> We will not transfer your personal information to any third party without seeking your consent, except in limited circumstances as described below: Ad service, Marketing agencies, and Analytics. </p>
      <p className=""> We require such third party’s to use the personal information we transfer to them only for the purpose for which it was transferred and not to retain it for longer than is required for fulfilling the said purpose. </p>
      <p className=""> We may also disclose your personal information for the following: (1) to comply with applicable law, regulation, court order or other legal process; (2) to enforce your agreements with us, including this Privacy Policy; or (3) to respond to claims that your use of the Service violates any third-party rights. If the Service or our company is merged or acquired with another company, your information will be one of the assets that is transferred to the new owner. </p>
      <h3>Retention Of Your Information:</h3>
      <p className=""> We will retain your personal information with us for 90 days to 2 years after users terminate their accounts or for as long as we need it to fulfill the purposes for which it was collected as detailed in this Privacy Policy. We may need to retain certain information for longer periods such as record-keeping / reporting in accordance with applicable law or for other legitimate reasons like enforcement of legal rights, fraud prevention, etc. Residual anonymous information and aggregate information, neither of which identifies you (directly or indirectly), may be stored indefinitely. </p>
      <h3>Your Rights</h3>
      <p className=""> Depending on the law that applies, you may have a right to access and rectify or erase your personal data or receive a copy of your personal data, restrict or object to the active processing of your data, ask us to share (port) your personal information to another entity, withdraw any consent you provided to us to process your data, a right to lodge a complaint with a statutory authority and such other rights as may be relevant under applicable laws. To exercise these rights, you can write to us at{" "} <a href="mailto:info@auxoinnovations.com" className="text-[#15c] underline"> info@auxoinnovations.com </a> . We will respond to your request in accordance with applicable law. </p>
      <p className=""> Do note that if you do not allow us to collect or process the required personal information or withdraw the consent to process the same for the required purposes, you may not be able to access or use the services for which your information was sought. </p>
      <h3>Cookies, etc.</h3>
      <p className=""> A cookie is an alphanumeric identifier which we transfer to your hard drive through your web browser when you visit our website. It enables our own system to recognize you when you visit our website again and improve our service to you. The information is used to track visitor use of the website and to compile statistical reports on website activity. For further information about cookies visit <a href="https://www.aboutcookies.org/" target="_blank" className="text-[#15c] underline mx-1.5"> www.aboutcookies.org </a> or <a href="https://www.allaboutcookies.org/" target="_blank" className="text-[#15c] underline mx-1.5"> www.aboutcookies.org </a> . Cookies may also be used to compile aggregate information about areas of our website that are visited most frequently. This traffic information can be used to enhance the content of our website and make your use of it easier. By accessing our website/Products, you agree to us placing cookies on your computer or device. If you wish to reject our cookie, you can configure your browser to do so. However, in a few cases some of our website/Products features may not function if you remove cookies from your browser. We shall not be liable for any malfunction or other such issue that may occur due to our use of cookies. </p>
      <h3>Security</h3>
      <p className=""> The security of your information is important to us and we will use reasonable security measures to prevent the loss, misuse or unauthorized alteration of your information under our control. However, given the inherent risks, we cannot guarantee absolute security and consequently, we cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk. </p>
      <h3>Grievance / Data Protection Officer</h3>
      <p className=""> If you have any queries or concerns about the processing of your information that is available with us, you may email our Company Officer at Auxo Innovations, 169 Madison Ave #2501, email: <a href="mailto:info@auxoinnovations.com" className="text-[#15c] underline"> info@auxoinnovations.com </a>. We will address your concerns in accordance with applicable law. </p>
  </div>
  );
};

export default PrivacyPolicy;
